import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";

import "../../scss/main.scss";

export default function Pricing() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link rel="shortcut icon" type="image/svg" href="img/favicon.svg" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Pricing</title>
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<section>
						<div className="pricing-landing">
							<StaticImage placeholder="blurred"
								className="pricing-header-img"
								src="../../static/assets/marketing-site/images/pricing-header-img.png"
								alt="Pricing Header image"
								loading="eager"
								placeholder="blurred"
							/>
							<h1>Our pricing</h1>
							<StaticImage placeholder="blurred"
								className="pricing-header-pattern large"
								src="../../static/assets/marketing-site/images/pricing-header-pattern.svg"
								alt="SVG pattern"
								loading="eager"
								placeholder="blurry"
							/>
							<StaticImage placeholder="blurred"
								className="pricing-header-pattern mobile" 
								src="../../static/assets/marketing-site/images/pricing-header-pattern-compact.svg"
							/>
						</div>
					</section>

					<section>
						<div className="three-tiers">
							<div className="three-tiers-left">
								<h3>
									We bracket our care packages into three different tiers{" "}
								</h3>
								<p className="primary">
									Our Care Advisors and Care Managers work with you and your
									family to ensure your family receives the right level of
									support. This is a collaborative process to ensure both the
									safety of your loved one and that you only pay for the support
									your family needs.
								</p>
								<p className="primary">
									Speak with one of our care managers to understand which tier
									is best suited to your situation.
								</p>
								<Link to="">
									<button className="btn-primary desktop_view">
										View the details of each tier
									</button>
								</Link>
							</div>
							<div className="three-tiers-right">
								<ul>
									<li className="tier-1">
										<h3>Tier 1:</h3>
										<p className="secondary">
											For those who need some assistance with daily active
											tasks.
										</p>
										<p className="three-tiers-price">From £950 weekly.</p>
									</li>
									<li className="tier-2">
										<h3>Tier 2:</h3>
										<p className="secondary">
											For those with conditions such as dementia, parkinsons and
											challenging requirements, like catheter and stoma.
										</p>
										<p className="three-tiers-price">From £1095 per week.</p>
									</li>
									<li className="tier-3">
										<h3>Tier 3:</h3>
										<p className="secondary">
											For those with more acute needs, requiring day-and-night
											support.
										</p>
										<p className="three-tiers-price">From £1550 per week.</p>
									</li>
									<Link to="" className="mobile_view">
										<button className="btn-primary">
											View the details of each tier
										</button>
									</Link>
								</ul>
							</div>
						</div>
					</section>

					<section>
						<div className="contact-us-banner">
							<StaticImage placeholder="blurred"
								className="pricing-postcode-people"
								src="../../static/assets/marketing-site/images/pricing-postcode-people.png"
								alt="Pricing People"
							/>
							<div className="contact-us-text">
								<p>
									Enter your postcode or speak with a friendly care advisor:
								</p>
								<h3>020 3970 9900</h3>
							</div>
							<div className="post-input-custom">
								<div>
									<input
										className="default"
										type="text"
										id="postalcode"
										name="postcode"
										placeholder="Enter your postcode"
									/>
								</div>
								<div>
									<Link to="">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/searchbtn.svg" />
									</Link>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="pricing-tier-details">
							<h3>A detailed view of our tiers</h3>
							{/*   PRICING TIER CARDS  */}
							<div className="pricing-tier-card tier-1-details">
								<div className="card-left">
									<div className="card-context">
										<p className="secondary rounded green"> Tier one </p>
										<br />
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/tier-1-person.png"
											alt="Tier 1 Person"
										/>
										<h1>Standard live-in care</h1>
										<br />
										<p className="secondary small">
											Those who need some support with daily active tasks such
											as household help and assistance around the home, as well
											as companionship.
										</p>
									</div>
									<div className="tier-discount wide-view">
										<ul>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/little-tier-guy.svg"
														alt="Tier Guy"
													/>
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price"> £950 per week </p>
												</div>
											</li>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price">
														{" "}
														£1,100 per week{" "}
													</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card-right">
									<div className="tier-includes">
										<h3>This package includes the following:</h3>
										<ul>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"
												/>
												<p>Household tasks </p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Medication support</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													Personal hygiene including assistance with dressing
													and toileting.
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Companionship and emotional support</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													Some night-time care, typically a twice a night or
													less
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="tier-discount mobile">
									<ul>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/little-tier-guy.svg"
													alt="Tier Guy"
												/>
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> £950 per week </p>
											</div>
										</li>
										<li className="divider green"></li>
										
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred" placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> £1,100 per week </p>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className="pricing-tier-card tier-2-details">
								<div className="card-left">
									<div className="card-context">
										<p className="secondary rounded blue"> Tier two </p>
										<br />
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/tier-2-person.png"
											alt="Tier 1 Person"
										/>
										<h1>Specialist live-in care</h1>
										<br />
										<p className="secondary small">
											Those who need some support with daily active tasks such
											as household help and assistance around the home, as well
											as companionship.
										</p>
									</div>
									<div className="tier-discount wide-view">
										<ul>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/little-tier-guy.svg"
														alt="Tier Guy"
													/>
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price">
														{" "}
														£1,095 per week{" "}
													</p>
												</div>
											</li>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price">
														{" "}
														£2,000 per week{" "}
													</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card-right">
									<div className="tier-includes">
										<h3>This package includes the following:</h3>
										<ul>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Household tasks </p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Medication support</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													More extensive personal hygiene, including help with
													stoma, peg or catheter
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													Hoisting - providing it can be completed by one person
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													Help with challenging behaviours, such as refusal to
													take medication
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Carer breaks to work around the care schedule</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="tier-discount mobile">
									<ul>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/little-tier-guy.svg"
													alt="Tier Guy"
												/>
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> £1,095 per week </p>
											</div>
										</li>
										<li className="divider blue"></li>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> £2,000 per week </p>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className="pricing-tier-card tier-3-details">
								<div className="card-left">
									<div className="card-context">
										<p className="secondary rounded orange"> Tier three </p>
										<br />
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/tier-3-person.png"
											alt="Tier 3 Person"
											objectFit='contain'
											className="enlarged"
										/>
										<h1>Advanced live-in care</h1>
										<br />
										<p className="secondary small">
											Those in need of significant round the clock care,
											throughout the day and night. Typically, for those with
											acute care needs, who require continuous and specialist
											assistance.
										</p>
									</div>
									<div className="tier-discount single wide-view">
										<ul>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/little-tier-guy.svg"
														alt="Tier Guy"
													/>
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price">
														{" "}
														£1,550 per week{" "}
													</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card-right">
									<div className="tier-includes">
										<h3>This package includes the following:</h3>
										<ul>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p className="secondary">
													Two live-in carers for round-the-clock care
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Household tasks</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													More extensive personal hygiene, including help with
													stoma, peg or catheter
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Extensive, frequent night-time care</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>Hoisting that requires more than one carer</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													Help with challengin behaviour, such as refusing
													support or meals
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
													alt="Checkmark"
													objectFit="contain"

												/>
												<p>
													Carer breaks designed to there's never a gap in
													support
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="tier-discount single mobile">
									<ul>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/little-tier-guy.svg"
													alt="Tier Guy"
												/>
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> £1,550 per week </p>
											</div>
										</li>
									</ul>
								</div>
							</div>
							{/*   END PRICING TIER CARD  */}

							<div className="additional-options">
								<div className="additional-options-left">
									<div className="title">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/aditional-icon.svg" objectFit="contain"/>
									</div>
									<div className="additional-options-content">
										<h1>
											Additional Options
										</h1>
										<p className="secondary pro">
											As well as deciding on your care package, you should also
											consider whether you want to add any of the following
											services to your family’s care package.
										</p>
									</div>
								</div>
								<div className="additional-options-right">
									<ul>
										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/additional-options-li.svg"
												alt="List Items"
												objectFit="contain"
											/>
											<p>
												Carer with a car or driving licence: If you need a carer
												who can drive or bring a car with them - £40 - £80
												Weekly
											</p>
										</li>
										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/additional-options-li.svg"
												alt="List Items"
												objectFit="contain"
											/>
											<p>
												Spousal support: if the carer is also helping another
												resident with light domestic duties - £100 weekly
											</p>
										</li>

										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/additional-options-li.svg"
												alt="List Items"
												objectFit="contain"
											/>
											<p>
												Managed food budget: If you'd like us to help manage the
												carer's daily food budget - £35 weekly
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>

					<hr className="pricing_hr"/>

					<section className="info-block-pricing-page pricing">
						<div className="paying-for-care">
							<div className="info-block-section left pricing">
								<h2>Paying for care guides</h2>
								<div className="info-block-content">
									<div className="infoblock read-more green">
										<h3>Transitioning to live-in care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more green">
										<h3>Live-in care for couples</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more green">
										<h3>Preparing for care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="info-block-pricing-page pricing">
						<div className="paying-for-care">
							<div className="info-block-section left pricing bottom-padding">
								<h2>Paying for care blogs</h2>
								<div className="info-block-content">
									<div className="infoblock read-more yellow">
										<h3>Transitioning to live-in care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>Live-in care for couples</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>Preparing for care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
			{/*   end of container  */}
			<Footer />
		</>
	);
}
